<template>
  <div class="py-md-5">
    <b-container fluid class="py-bw-5 section-header">
      <b-container>
        <b-row class="mb-4">
          <b-col cols="12">
            <p class="p-bw-bold highlighter bg-success mx-auto">
              {{ $t("common.gym") }}
            </p>
            <h1 class="display-bw-3">
              {{ $t("groupTraining.header.title") }}
            </h1>
            <p
              class="p-bw mb-md-5 px-5"
              v-html="$t('groupTraining.header.content')"
            ></p>
          </b-col>
        </b-row>
      </b-container>
    </b-container>

    <b-container fluid class="bg-info section--calender">
      <fitogram></fitogram>
    </b-container>

    <b-container
      id="outdoorWorkoutsClasses"
      class="py-8 section--outdoor-classes"
      style="display: none"
    >
      <b-row class="mb-4 mb-md-5">
        <b-col cols="12">
          <p class="p-bw-bold mx-auto mb-2">
            {{ $t("groupTraining.section1.subtitle") }}
          </p>
          <h2 class="h2-title">
            {{ $t("groupTraining.section1.title") }}
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div>
            <b-tabs pills fill content-class="mt-bw-90 shadow">
              <!-- Fundamental classes -->
              <b-tab
                :title="$t('groupTraining.CLASSES.FUNDAMENTALS.TITLE')"
                active
              >
                <div class="text-left">
                  <b-tabs vertical nav-wrapper-class="w-50">
                    <b-tab active>
                      <template #title>
                        <div class="d-flex flex-row">
                          <b-avatar
                            square
                            size="5em"
                            :src="require(`@/assets/imgs/tile_virtual_1.jpg`)"
                          ></b-avatar>
                          <div
                            class="w-100 d-flex flex-column justify-content-around px-4"
                          >
                            <div class="p-bw-bold">
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.A.TITLE"
                                )
                              }}
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.A.DURATION"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.A.AUDIENCE"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.A.EQUIPMENT"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <div class="d-flex flex-column">
                            <p
                              class="p-bw-bold highlighter bg-success mx-auto mb-4"
                            >
                              {{ $t("common.gym") }}
                            </p>
                            <h1 class="h1-bw mb-4">
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.A.TITLE"
                                )
                              }}
                            </h1>
                            <div class="p-bw mb-5">
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.A.DESCRIPTION"
                                )
                              }}
                              <br /><br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.A.DURATION"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.A.AUDIENCE_LONG"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.A.EQUIPMENT_LONG"
                                )
                              }}
                            </div>
                            <b-button
                              variant="dark"
                              class="w-100"
                              href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                              target="_blank"
                            >
                              {{ $t("common.btnBookClass") }}
                            </b-button>
                          </div>
                        </b-card>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template #title>
                        <div class="d-flex flex-row">
                          <b-avatar
                            square
                            size="5em"
                            :src="require(`@/assets/imgs/tile_virtual_2.jpg`)"
                          ></b-avatar>
                          <div
                            class="w-100 d-flex flex-column justify-content-around px-4"
                          >
                            <div class="p-bw-bold">
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.B.TITLE"
                                )
                              }}
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.B.DURATION"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.B.AUDIENCE"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.B.EQUIPMENT"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <div class="d-flex flex-column">
                            <p
                              class="p-bw-bold highlighter bg-success mx-auto mb-4"
                            >
                              {{ $t("common.gym") }}
                            </p>
                            <h1 class="h1-bw mb-4">
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.B.TITLE"
                                )
                              }}
                            </h1>
                            <div class="p-bw mb-5">
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.B.DESCRIPTION"
                                )
                              }}
                              <br /><br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.B.DURATION"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.B.AUDIENCE_LONG"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.B.EQUIPMENT_LONG"
                                )
                              }}
                            </div>
                            <b-button
                              variant="dark"
                              class="w-100"
                              href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                              target="_blank"
                            >
                              {{ $t("common.btnBookClass") }}
                            </b-button>
                          </div>
                        </b-card>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <div class="d-flex flex-row">
                          <b-avatar
                            square
                            size="5em"
                            :src="require(`@/assets/imgs/tile_virtual_1.jpg`)"
                          ></b-avatar>
                          <div
                            class="w-100 d-flex flex-column justify-content-around px-4"
                          >
                            <div class="p-bw-bold">
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.C.TITLE"
                                )
                              }}
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.C.DURATION"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.C.AUDIENCE"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.C.EQUIPMENT"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <div class="d-flex flex-column">
                            <p
                              class="p-bw-bold highlighter bg-success mx-auto mb-4"
                            >
                              {{ $t("common.gym") }}
                            </p>
                            <h1 class="h1-bw mb-4">
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.C.TITLE"
                                )
                              }}
                            </h1>
                            <div class="p-bw mb-5">
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.C.DESCRIPTION"
                                )
                              }}
                              <br /><br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.C.DURATION"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.C.AUDIENCE_LONG"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.C.EQUIPMENT_LONG"
                                )
                              }}
                            </div>
                            <b-button
                              variant="dark"
                              class="w-100"
                              href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                              target="_blank"
                            >
                              {{ $t("common.btnBookClass") }}
                            </b-button>
                          </div>
                        </b-card>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <div class="d-flex flex-row">
                          <b-avatar
                            square
                            size="5em"
                            :src="require(`@/assets/imgs/tile_virtual_1.jpg`)"
                          ></b-avatar>
                          <div
                            class="w-100 d-flex flex-column justify-content-around px-4"
                          >
                            <div class="p-bw-bold">
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.D.TITLE"
                                )
                              }}
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.D.DURATION"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.D.AUDIENCE"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.D.EQUIPMENT"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <div class="d-flex flex-column">
                            <p
                              class="p-bw-bold highlighter bg-success mx-auto mb-4"
                            >
                              {{ $t("common.gym") }}
                            </p>
                            <h1 class="h1-bw mb-4">
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.D.TITLE"
                                )
                              }}
                            </h1>
                            <div class="p-bw mb-5">
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.D.DESCRIPTION"
                                )
                              }}
                              <br /><br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.D.DURATION"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.D.AUDIENCE_LONG"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.FUNDAMENTALS.ITEMS.D.EQUIPMENT_LONG"
                                )
                              }}
                            </div>
                            <b-button
                              variant="dark"
                              class="w-100"
                              href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                              target="_blank"
                            >
                              {{ $t("common.btnBookClass") }}
                            </b-button>
                          </div>
                        </b-card>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </b-tab>

              <!-- Skills classes -->
              <b-tab :title="$t('groupTraining.CLASSES.SKILLS.TITLE')">
                <div class="text-left">
                  <b-tabs vertical nav-wrapper-class="w-50">
                    <b-tab active>
                      <template #title>
                        <div class="d-flex flex-row">
                          <b-avatar
                            square
                            size="5em"
                            :src="require(`@/assets/imgs/tile_virtual_1.jpg`)"
                          ></b-avatar>
                          <div
                            class="w-100 d-flex flex-column justify-content-around px-4"
                          >
                            <div class="p-bw-bold">
                              {{
                                $t("groupTraining.CLASSES.SKILLS.ITEMS.A.TITLE")
                              }}
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.SKILLS.ITEMS.A.DURATION"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.SKILLS.ITEMS.A.AUDIENCE"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.SKILLS.ITEMS.A.EQUIPMENT"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <div class="d-flex flex-column">
                            <p
                              class="p-bw-bold highlighter bg-success mx-auto mb-4"
                            >
                              {{ $t("common.gym") }}
                            </p>
                            <h1 class="h1-bw mb-4">
                              {{
                                $t("groupTraining.CLASSES.SKILLS.ITEMS.A.TITLE")
                              }}
                            </h1>
                            <div class="p-bw mb-5">
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.A.DESCRIPTION"
                                )
                              }}
                              <br /><br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.A.DURATION"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.A.AUDIENCE_LONG"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.A.EQUIPMENT_LONG"
                                )
                              }}
                            </div>
                            <b-button
                              variant="dark"
                              class="w-100"
                              href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                              target="_blank"
                            >
                              {{ $t("common.btnBookClass") }}
                            </b-button>
                          </div>
                        </b-card>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template #title>
                        <div class="d-flex flex-row">
                          <b-avatar
                            square
                            size="5em"
                            :src="require(`@/assets/imgs/tile_virtual_2.jpg`)"
                          ></b-avatar>
                          <div
                            class="w-100 d-flex flex-column justify-content-around px-4"
                          >
                            <div class="p-bw-bold">
                              {{
                                $t("groupTraining.CLASSES.SKILLS.ITEMS.B.TITLE")
                              }}
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.SKILLS.ITEMS.B.DURATION"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.SKILLS.ITEMS.B.AUDIENCE"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.SKILLS.ITEMS.B.EQUIPMENT"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <div class="d-flex flex-column">
                            <p
                              class="p-bw-bold highlighter bg-success mx-auto mb-4"
                            >
                              {{ $t("common.gym") }}
                            </p>
                            <h1 class="h1-bw mb-4">
                              {{
                                $t("groupTraining.CLASSES.SKILLS.ITEMS.B.TITLE")
                              }}
                            </h1>
                            <div class="p-bw mb-5">
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.B.DESCRIPTION"
                                )
                              }}
                              <br /><br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.B.DURATION"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.B.AUDIENCE_LONG"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.B.EQUIPMENT_LONG"
                                )
                              }}
                            </div>
                            <b-button
                              variant="dark"
                              class="w-100"
                              href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                              target="_blank"
                            >
                              {{ $t("common.btnBookClass") }}
                            </b-button>
                          </div>
                        </b-card>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <div class="d-flex flex-row">
                          <b-avatar
                            square
                            size="5em"
                            :src="require(`@/assets/imgs/tile_virtual_1.jpg`)"
                          ></b-avatar>
                          <div
                            class="w-100 d-flex flex-column justify-content-around px-4"
                          >
                            <div class="p-bw-bold">
                              {{
                                $t("groupTraining.CLASSES.SKILLS.ITEMS.C.TITLE")
                              }}
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.SKILLS.ITEMS.C.DURATION"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.SKILLS.ITEMS.C.AUDIENCE"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.SKILLS.ITEMS.C.EQUIPMENT"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <div class="d-flex flex-column">
                            <p
                              class="p-bw-bold highlighter bg-success mx-auto mb-4"
                            >
                              {{ $t("common.gym") }}
                            </p>
                            <h1 class="h1-bw mb-4">
                              {{
                                $t("groupTraining.CLASSES.SKILLS.ITEMS.C.TITLE")
                              }}
                            </h1>
                            <div class="p-bw mb-5">
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.C.DESCRIPTION"
                                )
                              }}
                              <br /><br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.C.DURATION"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.C.AUDIENCE_LONG"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.C.EQUIPMENT_LONG"
                                )
                              }}
                            </div>
                            <b-button
                              variant="dark"
                              class="w-100"
                              href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                              target="_blank"
                            >
                              {{ $t("common.btnBookClass") }}
                            </b-button>
                          </div>
                        </b-card>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <div class="d-flex flex-row">
                          <b-avatar
                            square
                            size="5em"
                            :src="require(`@/assets/imgs/tile_virtual_2.jpg`)"
                          ></b-avatar>
                          <div
                            class="w-100 d-flex flex-column justify-content-around px-4"
                          >
                            <div class="p-bw-bold">
                              {{
                                $t("groupTraining.CLASSES.SKILLS.ITEMS.D.TITLE")
                              }}
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.SKILLS.ITEMS.D.DURATION"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.SKILLS.ITEMS.D.AUDIENCE"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.SKILLS.ITEMS.D.EQUIPMENT"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <div class="d-flex flex-column">
                            <p
                              class="p-bw-bold highlighter bg-success mx-auto mb-4"
                            >
                              {{ $t("common.gym") }}
                            </p>
                            <h1 class="h1-bw mb-4">
                              {{
                                $t("groupTraining.CLASSES.SKILLS.ITEMS.D.TITLE")
                              }}
                            </h1>
                            <div class="p-bw mb-5">
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.D.DESCRIPTION"
                                )
                              }}
                              <br /><br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.D.DURATION"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.D.AUDIENCE_LONG"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.SKILLS.ITEMS.D.EQUIPMENT_LONG"
                                )
                              }}
                            </div>
                            <b-button
                              variant="dark"
                              class="w-100"
                              href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                              target="_blank"
                            >
                              {{ $t("common.btnBookClass") }}
                            </b-button>
                          </div>
                        </b-card>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </b-tab>

              <!-- Mobility classes -->
              <b-tab :title="$t('groupTraining.CLASSES.MOBILITY.TITLE')">
                <div class="text-left">
                  <b-tabs vertical nav-wrapper-class="w-50">
                    <b-tab active>
                      <template #title>
                        <div class="d-flex flex-row">
                          <b-avatar
                            square
                            size="5em"
                            :src="require(`@/assets/imgs/tile_virtual_1.jpg`)"
                          ></b-avatar>
                          <div
                            class="w-100 d-flex flex-column justify-content-around px-4"
                          >
                            <div class="p-bw-bold">
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.A.TITLE"
                                )
                              }}
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.MOBILITY.ITEMS.A.DURATION"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.MOBILITY.ITEMS.A.AUDIENCE"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.MOBILITY.ITEMS.A.EQUIPMENT"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <div class="d-flex flex-column">
                            <p
                              class="p-bw-bold highlighter bg-success mx-auto mb-4"
                            >
                              {{ $t("common.gym") }}
                            </p>
                            <h1 class="h1-bw mb-4">
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.A.TITLE"
                                )
                              }}
                            </h1>
                            <div class="p-bw mb-5">
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.A.DESCRIPTION"
                                )
                              }}
                              <br /><br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.A.DURATION"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.A.AUDIENCE_LONG"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.A.EQUIPMENT_LONG"
                                )
                              }}
                            </div>
                            <b-button
                              variant="dark"
                              class="w-100"
                              href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                              target="_blank"
                            >
                              {{ $t("common.btnBookClass") }}
                            </b-button>
                          </div>
                        </b-card>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template #title>
                        <div class="d-flex flex-row">
                          <b-avatar
                            square
                            size="5em"
                            :src="require(`@/assets/imgs/tile_virtual_2.jpg`)"
                          ></b-avatar>
                          <div
                            class="w-100 d-flex flex-column justify-content-around px-4"
                          >
                            <div class="p-bw-bold">
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.B.TITLE"
                                )
                              }}
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.MOBILITY.ITEMS.B.DURATION"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.MOBILITY.ITEMS.B.AUDIENCE"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.MOBILITY.ITEMS.B.EQUIPMENT"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <div class="d-flex flex-column">
                            <p
                              class="p-bw-bold highlighter bg-success mx-auto mb-4"
                            >
                              {{ $t("common.gym") }}
                            </p>
                            <h1 class="h1-bw mb-4">
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.B.TITLE"
                                )
                              }}
                            </h1>
                            <div class="p-bw mb-5">
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.B.DESCRIPTION"
                                )
                              }}
                              <br /><br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.B.DURATION"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.B.AUDIENCE_LONG"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.B.EQUIPMENT_LONG"
                                )
                              }}
                            </div>
                            <b-button
                              variant="dark"
                              class="w-100"
                              href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                              target="_blank"
                            >
                              {{ $t("common.btnBookClass") }}
                            </b-button>
                          </div>
                        </b-card>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <div class="d-flex flex-row">
                          <b-avatar
                            square
                            size="5em"
                            :src="require(`@/assets/imgs/tile_virtual_1.jpg`)"
                          ></b-avatar>
                          <div
                            class="w-100 d-flex flex-column justify-content-around px-4"
                          >
                            <div class="p-bw-bold">
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.C.TITLE"
                                )
                              }}
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.MOBILITY.ITEMS.C.DURATION"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.MOBILITY.ITEMS.C.AUDIENCE"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.MOBILITY.ITEMS.C.EQUIPMENT"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <div class="d-flex flex-column">
                            <p
                              class="p-bw-bold highlighter bg-success mx-auto mb-4"
                            >
                              {{ $t("common.gym") }}
                            </p>
                            <h1 class="h1-bw mb-4">
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.C.TITLE"
                                )
                              }}
                            </h1>
                            <div class="p-bw mb-5">
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.C.DESCRIPTION"
                                )
                              }}
                              <br /><br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.C.DURATION"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.C.AUDIENCE_LONG"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.C.EQUIPMENT_LONG"
                                )
                              }}
                            </div>
                            <b-button
                              variant="dark"
                              class="w-100"
                              href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                              target="_blank"
                            >
                              {{ $t("common.btnBookClass") }}
                            </b-button>
                          </div>
                        </b-card>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <div class="d-flex flex-row">
                          <b-avatar
                            square
                            size="5em"
                            :src="require(`@/assets/imgs/tile_virtual_2.jpg`)"
                          ></b-avatar>
                          <div
                            class="w-100 d-flex flex-column justify-content-around px-4"
                          >
                            <div class="p-bw-bold">
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.D.TITLE"
                                )
                              }}
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.MOBILITY.ITEMS.D.DURATION"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.MOBILITY.ITEMS.D.AUDIENCE"
                                  )
                                }}
                              </div>
                              <div class="p-bw">
                                {{
                                  $t(
                                    "groupTraining.CLASSES.MOBILITY.ITEMS.D.EQUIPMENT"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <div class="d-flex flex-column">
                            <p
                              class="p-bw-bold highlighter bg-success mx-auto mb-4"
                            >
                              {{ $t("common.gym") }}
                            </p>
                            <h1 class="h1-bw mb-4">
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.D.TITLE"
                                )
                              }}
                            </h1>
                            <div class="p-bw mb-5">
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.D.DESCRIPTION"
                                )
                              }}
                              <br /><br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.D.DURATION"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.D.AUDIENCE_LONG"
                                )
                              }}
                              <br />
                              {{
                                $t(
                                  "groupTraining.CLASSES.MOBILITY.ITEMS.D.EQUIPMENT_LONG"
                                )
                              }}
                            </div>
                            <b-button
                              variant="dark"
                              class="w-100"
                              href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                              target="_blank"
                            >
                              {{ $t("common.btnBookClass") }}
                            </b-button>
                          </div>
                        </b-card>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="bg-info section--traning-place">
      <b-container class="py-8 text-md-left section-training-location">
        <b-row>
          <b-col cols="12" md="6">
            <div
              class="img-group-training-group-a"
              alt="Barliner Workout"
            ></div>
          </b-col>
          <b-col cols="12" md="6" class="px-5 my-auto mt-sm-6">
            <div class="pl-5">
              <p class="p-bw-bold mb-2">
                {{ $t("groupTraining.section2.subtitle") }}
              </p>
              <h2 class="h2-bw mb-4">
                {{ $t("groupTraining.section2.title") }}
              </h2>
              <p class="p-bw" v-html="$t('groupTraining.section2.content')"></p>
              <b-button
                variant="dark"
                class="mt-4 w-75"
                href="https://g.page/EVOPEevolutionofperformance?share"
                target="_blank"
              >
                {{ $t("groupTraining.section2.buttonLabel") }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>

    <b-container id="outdoorWorkouts" class="py-8 section--outdoor-pricing">
      <b-row class="mb-5">
        <b-col cols="12">
          <p class="p-bw-bold mx-auto mb-2">
            {{ $t("groupTraining.section3.subtitle") }}
          </p>
          <h2 class="h2-title">
            {{ $t("groupTraining.section3.title") }}
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="">
          <b-card-group deck class="mx-0">
            <b-card
              border-variant="light"
              bg-variant="secondary"
              class="shadow card-bw"
            >
              <div class="d-flex flex-column" style="">
                <p class="p-bw-bold highlighter bg-success mx-auto mb-2">
                  {{ $t("common.gym") }}
                </p>
                <h3 class="h3-bw mb-3">
                  {{ $t("groupTraining.section3.card1.subtitle") }}
                </h3>
                <h1 class="h1-bw mb-3">
                  {{ $t("groupTraining.section3.card1.title") }}
                </h1>
                <p class="p-bw">
                  {{ $t("groupTraining.section3.card1.content") }}
                </p>
                <b-button
                  variant="dark"
                  class="w-100 mt-2"
                  href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                  target="_blank"
                >
                  {{ $t("common.buttonBuy") }}
                </b-button>
              </div>
            </b-card>
            <b-card
              border-variant="light"
              bg-variant="secondary"
              class="shadow card-bw"
            >
              <div class="d-flex flex-column" style="">
                <p class="p-bw-bold highlighter bg-success mx-auto mb-2">
                  {{ $t("common.gym") }}
                </p>
                <h3 class="h3-bw mb-3">
                  {{ $t("groupTraining.section3.card2.subtitle") }}
                </h3>
                <h1 class="h1-bw mb-3">
                  {{ $t("groupTraining.section3.card2.title") }}
                </h1>
                <p class="p-bw">
                  {{ $t("groupTraining.section3.card2.content") }}
                </p>
                <b-button
                  variant="dark"
                  class="w-100 mt-2"
                  href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                  target="_blank"
                >
                  {{ $t("common.buttonBuy") }}
                </b-button>
              </div>
            </b-card>
            <b-card
              border-variant="light"
              bg-variant="secondary"
              class="shadow card-bw"
            >
              <div class="d-flex flex-column" style="">
                <p class="p-bw-bold highlighter bg-success mx-auto mb-2">
                  {{ $t("common.gym") }}
                </p>
                <h3 class="h3-bw mb-3">
                  {{ $t("groupTraining.section3.card3.subtitle") }}
                </h3>
                <h1 class="h1-bw mb-3">
                  {{ $t("groupTraining.section3.card3.title") }}
                </h1>
                <p class="p-bw">
                  {{ $t("groupTraining.section3.card3.content") }}
                </p>
                <b-button
                  variant="dark"
                  class="w-100 mt-2"
                  href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                  target="_blank"
                >
                  {{ $t("common.buttonBuy") }}
                </b-button>
              </div>
            </b-card>
            <b-card
              border-variant="light"
              bg-variant="secondary"
              class="shadow card-bw"
            >
              <div class="d-flex flex-column" style="">
                <p class="p-bw-bold highlighter bg-success mx-auto mb-2">
                  {{ $t("common.gym") }}
                </p>
                <h3 class="h3-bw mb-3">
                  {{ $t("groupTraining.section3.card4.subtitle") }}
                </h3>
                <h1 class="h1-bw mb-3">
                  {{ $t("groupTraining.section3.card4.title") }}
                </h1>
                <p class="p-bw">
                  {{ $t("groupTraining.section3.card4.content") }}
                </p>
                <b-button
                  variant="dark"
                  class="w-100 mt-2"
                  href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                  target="_blank"
                >
                  {{ $t("common.buttonBuy") }}
                </b-button>
              </div>
            </b-card>
            <b-card
              border-variant="light"
              bg-variant="secondary"
              class="shadow card-bw"
            >
              <div class="d-flex flex-column" style="">
                <p class="p-bw-bold highlighter bg-success mx-auto mb-2">
                  {{ $t("common.gym") }}
                </p>
                <h3 class="h3-bw mb-3">
                  {{ $t("groupTraining.section3.card5.subtitle") }}
                </h3>
                <h1 class="h1-bw mb-3">
                  {{ $t("groupTraining.section3.card5.title") }}
                </h1>
                <p class="p-bw">
                  {{ $t("groupTraining.section3.card5.content") }}
                </p>
                <b-button
                  variant="dark"
                  class="w-100 mt-2"
                  href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                  target="_blank"
                >
                  {{ $t("common.buttonBuy") }}
                </b-button>
              </div>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
      <b-row class="pt-bw-4 px-md-5">
        <b-col>
          <p class="p-bw px-5">
            {{ $t("groupTraining.section3.content") }}
          </p>
          <b-button
            variant="outline-primary"
            class="px-5 mt-4"
            :to="{ name: 'Classes' }"
          >
            {{ $t("groupTraining.section3.buttonLabel") }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="bg-info py-bw-5 section--quotes">
      <b-row class="">
        <b-col class="">
          <swiper
            ref="quotesSwiper"
            :options="swiperOptionQuotes"
            :auto-destroy="true"
            :delete-instance-on-destroy="true"
            :cleanup-styles-on-destroy="true"
          >
            <swiper-slide :key="quote.id" v-for="quote in quotes">
              <b-container>
                <div class="mx-auto px-5">
                  <h3 class="h3-bw roboto-quote bg-quote mb-5 pt-5">
                    {{ quote.message }}
                  </h3>
                  <p class="p-bw-bold color-success">
                    — {{ quote.name }}, Barliner since {{ quote.joiningYear }}
                  </p>
                </div>
              </b-container>
            </swiper-slide>
            <div
              class="swiper-button-prev"
              slot="button-prev"
              @click="prevQuote()"
            ></div>
            <div
              class="swiper-button-next"
              slot="button-next"
              @click="nextQuote()"
            ></div>
          </swiper>
        </b-col>
      </b-row>
    </b-container>
    <contact></contact>
  </div>
</template>

<script>
export default {
  name: 'Inclusive',
  components: {
    Contact: () => import('@/components/Contact'),
    Fitogram: () => import('@/components/Fitogram')
  },
  data () {
    return {
      quotes: [
        {
          id: 10,
          name: 'Kirill',
          message:
            'Train till you faint. Nothing better than Barliner calithenics to 🔥 off that Corona fat and impress the Ex who dumped you during the lockdown.',
          joiningYear: '2020'
        },
        {
          id: 11,
          name: 'Mon',
          message:
            'I was immediately struck by how lovely and supportive everyone in this community is. When I came I couldn’t even do a single pull up, but with the help and experience of Denis I quickly overcame a lot of my difficulties and currently I am achieving movements that months ago sounded impossible.\n\n The care and knowledge that Denis displays in every single training session is unparalleled and I feel really grateful for being part of this family.',
          joiningYear: '2021'
        },
        {
          id: 12,
          name: 'Dung',
          message:
            "I started doing calisthenics in October 2019 at Barliner Calisthenics and I have to say that it's one of the best decisions I have ever made. Back then I was a complete beginner and couldn't do much of the calisthenics basics exercises but after a year the progress I've made blew my mind, and that is because Denis amazing teaching abilities.\n\n The workouts doesn't consist of just mindlessly repeating the exercises until you are able to do it, which usually takes more time to master the exercise, but of useful progressions which will reduce the risk of injury (due to too high ego) and leads you step by step to the advance skills you want to master. We exercise in groups but depending on your level Denis will take care your needs and assign you the exercises that will appropriately challenge you at your current level.\n\n Another positive aspect is the amazing Community at Barliner Calisthenics which makes the workouts fun and challenging. We keep pushing ourself to the limits which is very difficult if you're working out alone. Here at Barliner Calisthenics it is not only \"no pain no gain\" but because of Denis it is also \"no brain no gain\". 10/10!",
          joiningYear: '2019'
        },
        {
          id: 1,
          name: 'Alex',
          message:
            'Finding Barliner has been a massive part of my Berlin experience. Denis brings a knowledgeable, thoughtful, and non-judgemental approach to his training. The classes are great mix of basics and skills training, focusing on long-term development, rather than fast results. In addition to being an awesome trainer, Denis has also created a fun and supportive community for calisthenics enthusiasts of all levels.\n\n If you want to get fit, learn skills (the right way), and meet an awesome bunch of people, then this is place for you.',
          joiningYear: '2015'
        },
        {
          id: 2,
          name: 'Carsten',
          message:
            'Denis never gets tired of showing you, how to do the movements really correctly, down to the smallest muscles in your body. He always brings new exercises to his classes, and scaling options for every level of training. He’s a great support, and I got so much better over time. It makes so much fun to train with him. Thumbs Up for the best coach I can imagine!',
          joiningYear: '2019'
        },
        {
          id: 3,
          name: 'Liviu',
          message:
            'Keep up the sword of good cheer, and thank you again for your efforts and enthusiasm!',
          joiningYear: '2020'
        },
        {
          id: 4,
          name: 'Engin',
          message:
            'Ich genieße jedes Training und ganz gleich wie gut oder schlecht ich mich fühle vor dem Training, danach geht es mir immer besser. Es tut mir offensichtlich körperlich gut aber besonders mental ist es eine super Therapie.',
          joiningYear: '2020'
        },
        {
          id: 5,
          name: 'Nick',
          message:
            'Denis ist seit über einem Jahr mein Trainer. Meistens trainiere ich viermal pro Woche. Er bietet ein abwechslungsreiches und strukturiertes Training an. Es ist genau auf mein Level ausgerichtet und wird stetig an meine Fortschritte angepasst. Denis legt viel Wert auf das richtige Warm Up und die korrekte Ausführung von Bewegungen. Wir trainieren zuerst auf Bewegungen hin und im zweiten Schritt auf Wiederholungen und Gewichte. Das Besondere an Denis ist, ist dass er alle Übungen selbst perfekt beherrscht und immer motiviert ist. Das Training macht sehr viel Spaß!',
          joiningYear: '2019'
        },
        {
          id: 6,
          name: 'Mara',
          message:
            'Right from the beginning of the first training I felt so welcome and like I was part of the group. It’s great how a group of so many different people can be such a strong team and still be so open to accept new people right away. I absolutely love the spirit of our crew. Everybody looks out for each other, supports their teammates and motivates and corrects them. Which is why the atmosphere at every training is very good and also intense. That is one of the reasons why I can push myself harder than I could before. \n\nAnother awesome thing is that the training is different every time and Denis always looks for specific exercises that are the right ones for you. I just started but I can already feel improvements (not only physical but also in my mindset) and have to say, that this is an important part of my time here in Berlin. Cheers!',
          joiningYear: '2017'
        },
        {
          id: 7,
          name: 'Oksana',
          message:
            'Joined the comunity 5 years ago. We went through many things. I like to see the progress of the community. Thanks to Barliner I got motivated to do a Trainer licence and it brought me to my jobs as a trainer today. Specially, I like this feeling in the training with Barliner. People are very cool, heartwarming atmosphere, a lot of nonstandard exercises!',
          joiningYear: '2016'
        },
        {
          id: 8,
          name: 'Simon',
          message:
            'If you want to: be trained by one of the nicest guys you will ever meet; train alongside a community of friendly, focused and hard-working individuals from around the world; improve your strength, flexibility and muscular definition in a more efficient way than you will ever do in a gym; and have a s**t-tonne of fun and laughs while doing it, then training with Denis at Barliner is the place for you. Well, at least, this has been my experience so far!\n\n I am so so happy I joined the Barliner clan and discovered calisthenics. Every week I am making progress and every week I am buzzing to train. Denis always keeps the sessions fresh and exciting, pushes you to your absolutel limit and manages to do so while making both you and he smile.\n\n Thanks to Denis and everyone at Barliner for the love and support!',
          joiningYear: '2016'
        },
        {
          id: 9,
          name: 'Robyn',
          message:
            'Gymnastics for adults!\n\n After 11 months of looking for a sport that pushes my mind and body I finally went to one of the Barliner Calisthenics classes and fell in love! I had done crossfit for a few years but it really lacked enough focus in the gymnastic element of the training.\n\n I love this sport, both as a mental challenge and as exercise to tone my body and push me further every time. Additionally, it is great finding a sport with such a friendly group of people like the Barliner Calisthenics community.\n\n And the best thing...Den is an amazing instructor. He pushes you but is aware of injuries. I have been to several calisthenics classes in Berlin but now I only go to his classes as he is THE BEST!!!',
          joiningYear: '2019'
        }
      ],
      swiperOptionQuotes: {
        loop: true,
        grabCursor: true,
        initialSlide: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    swiperQuotes () {
      return this.$refs.quotesSwiper.$swiper
    }
  },
  methods: {
    prevQuote () {
      this.swiperQuotes.slidePrev()
    },
    nextQuote () {
      this.swiperQuotes.slideNext()
    }
  }
}
</script>

<style lang="scss" scoped>
.section-header {
  .card-trainer {
    height: 480px;
    margin-right: 0.75rem;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.section-training-location {
  .img-group-training-group-a {
    @extend .img-background;
    height: 100%;
    min-height: 492px;
    background-image: url("../assets/imgs/group_training_group_a_3x.jpg");
    background-size: cover;
    background-position: center;
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: $primary;
  &::after {
    font-size: 1.5rem;
    font-weight: 900;
  }
}

.bg-quote {
  @extend .img-background;

  white-space: pre-line;
  background-size: 15%;
  background-position: center top;
  background-image: url("../assets/imgs/bg-quote-success.svg");
}
</style>
